<template>
  <div>
    <v-dialog v-model="dialog" width="80%" persistent>
      <v-card>
        <v-card-title class="grey lighten-2">
          รายชื่อ รับรายงานตัวครู ย้ายกรณีปกติ
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา"
            single-line
            hide-details
            dense
            filled
            class="mb-2"
          />

          <v-data-table
            :search="search"
            :items="conditons_transfer_successs"
            :headers="headers"
          >
          <template v-slot:item.cts_report="{ item }">
            <div v-if="item.cts_college_cc === 'CC'">
            
                <v-icon color="warning">mdi-information</v-icon>
                ขอยกเลิกรายการ
            </div>
            <div v-else>
              <div v-if="item.cts_report === 'nb'">
                <v-chip color="primary" dark>มารายงานตัวปกติ</v-chip>
              </div>
              <div v-else-if="item.cts_report === 'dnb'">
                <v-chip color="warning" dark>ไม่มารายงานตัว</v-chip>
              </div>
              <div color="red" v-else dark>
                <v-chip color="red" dark> <v-icon>mdi-alert</v-icon> ไม่ได้ดำเนินการ</v-chip>
              </div>
            </div>
          </template>

          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "HRvecManageAssistantTeacher",
  props: ["OpenDialog_conditons_transfer_success"],

  data() {
    return {
      ApiKey: "HRvec2021",
      dialog: false,
      periods: [],
      conditons_transfer_successs: [],
      headers: [
        { text: "สังกัดใหม่", align: "left", value: "college_name_new" },
        { text: "คำนำหน้าชื่อ", align: "center", value: "title_s" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },     
        { text: "สังกัดเดิม", align: "left", value: "college_name_old" },
        { text: "เลขที่", align: "center", value: "ctsid_position" },      
        { text: "สถานะ", align: "center", value: "cts_report" },
      ],
      search: ""
    };
  },

  async mounted() {},

  methods: {
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_type: "teacher"
      });
      this.periods = result_period.data;
    },

    async conditons_transfer_success() {
      let result = await this.$http
        .post("conditons_transfer_success.php", {
          ApiKey: this.ApiKey,
          ctstime_s: this.periods.period_times,
          ctsyear_s: this.periods.period_year,
          showHome:"ok"
        })
        .finally(() => (this.loading = false));
      this.conditons_transfer_successs = result.data;
      
    },

    async RunDialog() {
      if (this.OpenDialog_conditons_transfer_success == true) {
        this.dialog = true;
        
      }
    },

    async closeDialog() {
      this.$emit("toggle-favorite", this.OpenDialog_conditons_transfer_success);
      this.dialog = false;
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  watch: {
    async OpenDialog_conditons_transfer_success(newVal, oldVal) {
      if (this.OpenDialog_conditons_transfer_success == true) {
        await this.sweetAlertLoading();
        await this.periodQuery();
        await this.conditons_transfer_success();
        await this.RunDialog();
        Swal.close(); 
      
      }
    }
  }
};
</script>
